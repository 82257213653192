import React from "react";
import Layout from "../components/Layout";
import ProductPage from "../components/ProductPage/ProductPage";
import { hamProducts } from "../data/ham/hamProduct";
import { hamRecipes } from "../data/hamRecipes";
import RecipeGrid from "../components/RecipeGrid/RecipeGrid";
import InspirationGrid from "../components/InspirationGrid/InspirationGrid";
import { turkeyRecipes } from "../data/turkeyRecipes";
import { graphql } from "gatsby";

import _ from "lodash";
import { getImage } from "gatsby-plugin-image";
const Ham = (props) => {
  const dataArray = [...hamRecipes, ...turkeyRecipes];

  const filterData = _.filter(dataArray, (obj) => {
    return _.includes(obj.filterProtein, "ham");
  });
  const hamImg = getImage(props.data.allFile.edges[0].node);
  return (
    <Layout title="Ham" description="">
      <main>
        <ProductPage
          image={hamImg}
          proteinProduct={hamProducts}
          ProductName="Ham"
        />
        <div>
          <RecipeGrid
            filteredCards={filterData}
            text={"DELICIOUS HAM RECIPES"}
          />
        </div>

        <div>
          <InspirationGrid filterSelected="ham" text={"HAM INSPIRATIONS"} />
        </div>
      </main>
    </Layout>
  );
};
export const query = graphql`
  query {
    allFile(filter: { relativePath: { in: "ham/SECTION-HEADER-HAM-1.jpg" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              blurredOptions: { toFormat: WEBP }
              formats: WEBP
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 100
              webpOptions: { quality: 100 }
            )
          }
        }
      }
    }
  }
`;

export default Ham;
